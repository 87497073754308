import React from 'react';
import {graphql} from 'gatsby';
import {Location} from '@reach/router';
import loadable from '@loadable/component';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Layout = loadable(() => import('../components/Layout'));
import './EquipePage.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faInfo} from '@fortawesome/free-solid-svg-icons';


// Export Template for use in CMS preview
export const EquipePageTemplate = ({
                                       title,
                                       subtitle,
                                       featuredImage,
                                       membres = []
                                   }) => (
    <Location>
        {({location}) => {
            const assistantes = membres.filter(membre => membre.job.indexOf('Assistant') > -1);
            const dentistes = membres.filter(membre => membre.job.indexOf('Assistant') === -1);

            return (
                <main className="equipePage">
                    <PageHeader
                        large
                        title=""
                        subtitle=""
                        backgroundImage={featuredImage}
                    />

                    <div className="equipePage-container m-auto">
                        <h2 className="mt-8">Les chirurgiens dentistes</h2>
                        <hr/>

                        <div className="flex w-full flex-wrap p-8 justify-center">
                            {dentistes.map((membre, index) => (
                                <div className="equipePage-box" key={membre.name + index}>
                                    <div className="equipePage-img-box">
                                        <img  src={membre.featuredImage} alt={membre.name}>
                                        </img>
                                    </div>
                                    <ul className="equipePage-profil-icon">
                                        <li><a href="tel:0561510561" className="flex justify-center items-center"><FontAwesomeIcon icon={faPhoneAlt} className="text-3xl"/></a></li>
                                        <li><a href={membre.slug} className="flex justify-center items-center"><FontAwesomeIcon icon={faInfo} className="text-3xl"/></a></li>
                                    </ul>
                                    <div className="equipePage-membre-details text-center">
                                        <h3 className="font-bold p-0 m-0">{membre.firstname} {membre.name}
                                            <br/><span>{membre.job}</span></h3>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <h2>Les assistantes</h2>
                        <hr/>

                        <div className="flex w-full flex-wrap p-8 justify-center">
                            {assistantes.map((membre, index) => (

                                <div className="equipePage-box" key={membre.name + index}>
                                    <div className="equipePage-img-box">
                                        <img src={membre.featuredImage} alt={membre.name}>
                                        </img>
                                    </div>
                                    <div className="equipePage-membre-details text-center">
                                        <h3 className="font-bold p-0 m-0">{membre.firstname}
                                            <br/><span>{membre.job}</span></h3>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>


                </main>
            )
        }}
    </Location>
);

// Export Default CabinetPage for front-end
const EquipePage = ({data: {page, membres}}) => (
    <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
    >
        <EquipePageTemplate
            {...page}
            {...page.fields}
            {...page.frontmatter}
            membres={membres.edges.map(membre => ({
                ...membre.node,
                ...membre.node.frontmatter,
                ...membre.node.fields
            }))}
        />
    </Layout>
);

export default EquipePage

export const pageQuery = graphql`
  query EquipePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
      }
    }

    membres: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "membres" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            name
            firstname
            job
            featuredImage
          }
        }
      }
    }
  }
`;
